// eslint-disable-next-line import/prefer-default-export
export const Timezones = [
  {
    id: 1,
    title: 'Pacific/Midway',
    value: '(UTC-11:00) Midway Island',
  },
  {
    id: 2,
    title: 'Pacific/Samoa',
    value: '(UTC-11:00) Samoa',
  },
  {
    id: 3,
    title: 'Pacific/Honolulu',
    value: '(UTC-10:00) Hawaii',
  },
  {
    id: 4,
    title: 'US/Alaska',
    value: '(UTC-09:00) Alaska',
  },
  {
    id: 5,
    title: 'America/Los_Angeles',
    value: '(UTC-08:00) Pacific Time (US &amp; Canada)',
  },
  {
    id: 6,
    title: 'America/Tijuana',
    value: '(UTC-08:00) Tijuana',
  },
  {
    id: 7,
    title: 'US/Arizona',
    value: '(UTC-07:00) Arizona',
  },
  {
    id: 8,
    title: 'America/Chihuahua',
    value: '(UTC-07:00) Chihuahua',
  },
  {
    id: 9,
    title: 'America/Chihuahua',
    value: '(UTC-07:00) La Paz',
  },
  {
    id: 10,
    title: 'America/Mazatlan',
    value: '(UTC-07:00) Mazatlan',
  },
  {
    id: 11,
    title: 'US/Mountain',
    value: '(UTC-07:00) Mountain Time (US &amp; Canada)',
  },
  {
    id: 12,
    title: 'America/Managua',
    value: '(UTC-06:00) Central America',
  },
  {
    id: 13,
    title: 'US/Central',
    value: '(UTC-06:00) Central Time (US &amp; Canada)',
  },
  {
    id: 14,
    title: 'America/Mexico_City',
    value: '(UTC-06:00) Guadalajara',
  },
  {
    id: 15,
    title: 'America/Mexico_City',
    value: '(UTC-06:00) Mexico City',
  },
  {
    id: 16,
    title: 'America/Monterrey',
    value: '(UTC-06:00) Monterrey',
  },
  {
    id: 17,
    title: 'Canada/Saskatchewan',
    value: '(UTC-06:00) Saskatchewan',
  },
  {
    id: 18,
    title: 'America/Bogota',
    value: '(UTC-05:00) Bogota',
  },
  {
    id: 19,
    title: 'US/Eastern',
    value: '(UTC-05:00) Eastern Time (US &amp; Canada)',
  },
  {
    id: 20,
    title: 'US/East-Indiana',
    value: '(UTC-05:00) Indiana (East)',
  },
  {
    id: 21,
    title: 'America/Lima',
    value: '(UTC-05:00) Lima',
  },
  {
    id: 22,
    title: 'America/Bogota',
    value: '(UTC-05:00) Quito',
  },
  {
    id: 23,
    title: 'Canada/Atlantic',
    value: '(UTC-04:00) Atlantic Time (Canada)',
  },
  {
    id: 24,
    title: 'America/Caracas',
    value: '(UTC-04:30) Caracas',
  },
  {
    id: 25,
    title: 'America/La_Paz',
    value: '(UTC-04:00) La Paz',
  },
  {
    id: 26,
    title: 'America/Santiago',
    value: '(UTC-04:00) Santiago',
  },
  {
    id: 27,
    title: 'Canada/Newfoundland',
    value: '(UTC-03:30) Newfoundland',
  },
  {
    id: 28,
    title: 'America/Sao_Paulo',
    value: '(UTC-03:00) Brasilia',
  },
  {
    id: 29,
    title: 'America/Argentina/Buenos_Aires',
    value: '(UTC-03:00) Buenos Aires',
  },
  {
    id: 30,
    title: 'America/Argentina/Buenos_Aires',
    value: '(UTC-03:00) Georgetown',
  },
  {
    id: 31,
    title: 'America/Godthab',
    value: '(UTC-03:00) Greenland',
  },
  {
    id: 32,
    title: 'America/Noronha',
    value: '(UTC-02:00) Mid-Atlantic',
  },
  {
    id: 33,
    title: 'Atlantic/Azores',
    value: '(UTC-01:00) Azores',
  },
  {
    id: 34,
    title: 'Atlantic/Cape_Verde',
    value: '(UTC-01:00) Cape Verde Is.',
  },
  {
    id: 35,
    title: 'Africa/Casablanca',
    value: '(UTC+00:00) Casablanca',
  },
  {
    id: 36,
    title: 'Europe/London',
    value: '(UTC+00:00) Edinburgh',
  },
  {
    id: 37,
    title: 'Etc/Greenwich',
    value: '(UTC+00:00) Greenwich Mean Time : Dublin',
  },
  {
    id: 38,
    title: 'Europe/Lisbon',
    value: '(UTC+00:00) Lisbon',
  },
  {
    id: 39,
    title: 'Europe/London',
    value: '(UTC+00:00) London',
  },
  {
    id: 40,
    title: 'Africa/Monrovia',
    value: '(UTC+00:00) Monrovia',
  },
  {
    id: 41,
    title: 'UTC',
    value: '(UTC+00:00) UTC',
  },
  {
    id: 42,
    title: 'Europe/Amsterdam',
    value: '(UTC+01:00) Amsterdam',
  },
  {
    id: 43,
    title: 'Europe/Belgrade',
    value: '(UTC+01:00) Belgrade',
  },
  {
    id: 44,
    title: 'Europe/Berlin',
    value: '(UTC+01:00) Berlin',
  },
  {
    id: 45,
    title: 'Europe/Berlin',
    value: '(UTC+01:00) Bern',
  },
  {
    id: 46,
    title: 'Europe/Bratislava',
    value: '(UTC+01:00) Bratislava',
  },
  {
    id: 47,
    title: 'Europe/Brussels',
    value: '(UTC+01:00) Brussels',
  },
  {
    id: 48,
    title: 'Europe/Budapest',
    value: '(UTC+01:00) Budapest',
  },
  {
    id: 49,
    title: 'Europe/Copenhagen',
    value: '(UTC+01:00) Copenhagen',
  },
  {
    id: 50,
    title: 'Europe/Ljubljana',
    value: '(UTC+01:00) Ljubljana',
  },
  {
    id: 51,
    title: 'Europe/Madrid',
    value: '(UTC+01:00) Madrid',
  },
  {
    id: 52,
    title: 'Europe/Paris',
    value: '(UTC+01:00) Paris',
  },
  {
    id: 53,
    title: 'Europe/Prague',
    value: '(UTC+01:00) Prague',
  },
  {
    id: 54,
    title: 'Europe/Rome',
    value: '(UTC+01:00) Rome',
  },
  {
    id: 55,
    title: 'Europe/Sarajevo',
    value: '(UTC+01:00) Sarajevo',
  },
  {
    id: 56,
    title: 'Europe/Skopje',
    value: '(UTC+01:00) Skopje',
  },
  {
    id: 57,
    title: 'Europe/Stockholm',
    value: '(UTC+01:00) Stockholm',
  },
  {
    id: 58,
    title: 'Europe/Vienna',
    value: '(UTC+01:00) Vienna',
  },
  {
    id: 59,
    title: 'Europe/Warsaw',
    value: '(UTC+01:00) Warsaw',
  },
  {
    id: 60,
    title: 'Africa/Lagos',
    value: '(UTC+01:00) West Central Africa',
  },
  {
    id: 61,
    title: 'Europe/Zagreb',
    value: '(UTC+01:00) Zagreb',
  },
  {
    id: 62,
    title: 'Europe/Athens',
    value: '(UTC+02:00) Athens',
  },
  {
    id: 63,
    title: 'Europe/Bucharest',
    value: '(UTC+02:00) Bucharest',
  },
  {
    id: 64,
    title: 'Africa/Cairo',
    value: '(UTC+02:00) Cairo',
  },
  {
    id: 65,
    title: 'Africa/Harare',
    value: '(UTC+02:00) Harare',
  },
  {
    id: 66,
    title: 'Europe/Helsinki',
    value: '(UTC+02:00) Helsinki',
  },
  {
    id: 67,
    title: 'Europe/Istanbul',
    value: '(UTC+02:00) Istanbul',
  },
  {
    id: 68,
    title: 'Asia/Jerusalem',
    value: '(UTC+02:00) Jerusalem',
  },
  {
    id: 69,
    title: 'Europe/Helsinki',
    value: '(UTC+02:00) Kyiv',
  },
  {
    id: 70,
    title: 'Africa/Johannesburg',
    value: '(UTC+02:00) Pretoria',
  },
  {
    id: 71,
    title: 'Europe/Riga',
    value: '(UTC+02:00) Riga',
  },
  {
    id: 72,
    title: 'Europe/Sofia',
    value: '(UTC+02:00) Sofia',
  },
  {
    id: 73,
    title: 'Europe/Tallinn',
    value: '(UTC+02:00) Tallinn',
  },
  {
    id: 74,
    title: 'Europe/Vilnius',
    value: '(UTC+02:00) Vilnius',
  },
  {
    id: 75,
    title: 'Asia/Baghdad',
    value: '(UTC+03:00) Baghdad',
  },
  {
    id: 76,
    title: 'Asia/Kuwait',
    value: '(UTC+03:00) Kuwait',
  },
  {
    id: 77,
    title: 'Europe/Minsk',
    value: '(UTC+03:00) Minsk',
  },
  {
    id: 78,
    title: 'Africa/Nairobi',
    value: '(UTC+03:00) Nairobi',
  },
  {
    id: 79,
    title: 'Asia/Riyadh',
    value: '(UTC+03:00) Riyadh',
  },
  {
    id: 80,
    title: 'Europe/Volgograd',
    value: '(UTC+03:00) Volgograd',
  },
  {
    id: 81,
    title: 'Asia/Tehran',
    value: '(UTC+03:30) Tehran',
  },
  {
    id: 82,
    title: 'Asia/Muscat',
    value: '(UTC+04:00) Abu Dhabi',
  },
  {
    id: 83,
    title: 'Asia/Baku',
    value: '(UTC+04:00) Baku',
  },
  {
    id: 84,
    title: 'Europe/Moscow',
    value: '(UTC+04:00) Moscow',
  },
  {
    id: 85,
    title: 'Asia/Muscat',
    value: '(UTC+04:00) Muscat',
  },
  {
    id: 86,
    title: 'Europe/Moscow',
    value: '(UTC+04:00) St. Petersburg',
  },
  {
    id: 87,
    title: 'Asia/Tbilisi',
    value: '(UTC+04:00) Tbilisi',
  },
  {
    id: 88,
    title: 'Asia/Yerevan',
    value: '(UTC+04:00) Yerevan',
  },
  {
    id: 89,
    title: 'Asia/Kabul',
    value: '(UTC+04:30) Kabul',
  },
  {
    id: 90,
    title: 'Asia/Karachi',
    value: '(UTC+05:00) Islamabad',
  },
  {
    id: 91,
    title: 'Asia/Karachi',
    value: '(UTC+05:00) Karachi',
  },
  {
    id: 92,
    title: 'Asia/Tashkent',
    value: '(UTC+05:00) Tashkent',
  },
  {
    id: 93,
    title: 'Asia/Calcutta',
    value: '(UTC+05:30) Chennai',
  },
  {
    id: 94,
    title: 'Asia/Kolkata',
    value: '(UTC+05:30) Kolkata',
  },
  {
    id: 95,
    title: 'Asia/Calcutta',
    value: '(UTC+05:30) Mumbai',
  },
  {
    id: 96,
    title: 'Asia/Calcutta',
    value: '(UTC+05:30) New Delhi',
  },
  {
    id: 97,
    title: 'Asia/Calcutta',
    value: '(UTC+05:30) Sri Jayawardenepura',
  },
  {
    id: 98,
    title: 'Asia/Katmandu',
    value: '(UTC+05:45) Kathmandu',
  },
  {
    id: 99,
    title: 'Asia/Almaty',
    value: '(UTC+06:00) Almaty',
  },
  {
    id: 100,
    title: 'Asia/Dhaka',
    value: '(UTC+06:00) Astana',
  },
  {
    id: 101,
    title: 'Asia/Dhaka',
    value: '(UTC+06:00) Dhaka',
  },
  {
    id: 102,
    title: 'Asia/Yekaterinburg',
    value: '(UTC+06:00) Ekaterinburg',
  },
  {
    id: 103,
    title: 'Asia/Rangoon',
    value: '(UTC+06:30) Rangoon',
  },
  {
    id: 104,
    title: 'Asia/Bangkok',
    value: '(UTC+07:00) Bangkok',
  },
  {
    id: 105,
    title: 'Asia/Bangkok',
    value: '(UTC+07:00) Hanoi',
  },
  {
    id: 106,
    title: 'Asia/Jakarta',
    value: '(UTC+07:00) Jakarta',
  },
  {
    id: 107,
    title: 'Asia/Novosibirsk',
    value: '(UTC+07:00) Novosibirsk',
  },
  {
    id: 108,
    title: 'Asia/Hong_Kong',
    value: '(UTC+08:00) Beijing',
  },
  {
    id: 109,
    title: 'Asia/Chongqing',
    value: '(UTC+08:00) Chongqing',
  },
  {
    id: 110,
    title: 'Asia/Hong_Kong',
    value: '(UTC+08:00) Hong Kong',
  },
  {
    id: 111,
    title: 'Asia/Krasnoyarsk',
    value: '(UTC+08:00) Krasnoyarsk',
  },
  {
    id: 112,
    title: 'Asia/Kuala_Lumpur',
    value: '(UTC+08:00) Kuala Lumpur',
  },
  {
    id: 113,
    title: 'Australia/Perth',
    value: '(UTC+08:00) Perth',
  },
  {
    id: 114,
    title: 'Asia/Singapore',
    value: '(UTC+08:00) Singapore',
  },
  {
    id: 115,
    title: 'Asia/Taipei',
    value: '(UTC+08:00) Taipei',
  },
  {
    id: 116,
    title: 'Asia/Ulan_Bator',
    value: '(UTC+08:00) Ulaan Bataar',
  },
  {
    id: 117,
    title: 'Asia/Urumqi',
    value: '(UTC+08:00) Urumqi',
  },
  {
    id: 118,
    title: 'Asia/Irkutsk',
    value: '(UTC+09:00) Irkutsk',
  },
  {
    id: 119,
    title: 'Asia/Tokyo',
    value: '(UTC+09:00) Osaka',
  },
  {
    id: 120,
    title: 'Asia/Tokyo',
    value: '(UTC+09:00) Sapporo',
  },
  {
    id: 121,
    title: 'Asia/Seoul',
    value: '(UTC+09:00) Seoul',
  },
  {
    id: 122,
    title: 'Asia/Tokyo',
    value: '(UTC+09:00) Tokyo',
  },
  {
    id: 123,
    title: 'Australia/Adelaide',
    value: '(UTC+09:30) Adelaide',
  },
  {
    id: 124,
    title: 'Australia/Darwin',
    value: '(UTC+09:30) Darwin',
  },
  {
    id: 125,
    title: 'Australia/Brisbane',
    value: '(UTC+10:00) Brisbane',
  },
  {
    id: 126,
    title: 'Australia/Canberra',
    value: '(UTC+10:00) Canberra',
  },
  {
    id: 127,
    title: 'Pacific/Guam',
    value: '(UTC+10:00) Guam',
  },
  {
    id: 128,
    title: 'Australia/Hobart',
    value: '(UTC+10:00) Hobart',
  },
  {
    id: 129,
    title: 'Australia/Melbourne',
    value: '(UTC+10:00) Melbourne',
  },
  {
    id: 130,
    title: 'Pacific/Port_Moresby',
    value: '(UTC+10:00) Port Moresby',
  },
  {
    id: 131,
    title: 'Australia/Sydney',
    value: '(UTC+10:00) Sydney',
  },
  {
    id: 132,
    title: 'Asia/Yakutsk',
    value: '(UTC+10:00) Yakutsk',
  },
  {
    id: 133,
    title: 'Asia/Vladivostok',
    value: '(UTC+11:00) Vladivostok',
  },
  {
    id: 134,
    title: 'Pacific/Auckland',
    value: '(UTC+12:00) Auckland',
  },
  {
    id: 135,
    title: 'Pacific/Fiji',
    value: '(UTC+12:00) Fiji',
  },
  {
    id: 136,
    title: 'Pacific/Kwajalein',
    value: '(UTC+12:00) International Date Line West',
  },
  {
    id: 137,
    title: 'Asia/Kamchatka',
    value: '(UTC+12:00) Kamchatka',
  },
  {
    id: 138,
    title: 'Asia/Magadan',
    value: '(UTC+12:00) Magadan',
  },
  {
    id: 139,
    title: 'Pacific/Fiji',
    value: '(UTC+12:00) Marshall Is.',
  },
  {
    id: 140,
    title: 'Asia/Magadan',
    value: '(UTC+12:00) New Caledonia',
  },
  {
    id: 141,
    title: 'Asia/Magadan',
    value: '(UTC+12:00) Solomon Is.',
  },
  {
    id: 142,
    title: 'Pacific/Auckland',
    value: '(UTC+12:00) Wellington',
  },
  {
    id: 143,
    title: 'Pacific/Tongatapu',
    value: '(UTC+13:00) Nukualofa',
  },
]
